import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import { DataTable } from '../../components/DataTable'
import { Highlights } from '../../components/Highlights'
import { Modal } from '../../components/Modal'
import { MultiSelectDropdown } from '../../components/MultiSelectDropdown'
import { StateContainer } from '../../components/StateContainer'
import DealerNameCell from './DealerNameCell'
// Icons
import UserIcon from '../../assets/images/user_icon.svg'

// Store
import { DashboardStoreContext } from '../../stores/DashboardStore'
import { RootStoreContext } from '../../stores/RootStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import { getDealerKpis, getDealers, getFilterListOptions } from '../../services/dealers.service'
import { logout, updateUser } from '../../services/user.service'
import { handlePagination, toast } from '../../utils/helpers'
import useHoverItem from '../../hooks/HoverItem'

const DEALER_BASE_URL = '/dealers/?expand=group,manufacturer,ro_metrics'
const KPI_BASE_URL = '/dealers/kpis'
const DEALER_GROUP_BASE_URL = '/dealers/dealer-groups/'
const MANUFACTURER_BASE_URL = '/dealers/manufacturers/'
const ADMIN_BASE_URL = '/dealers/admins/'
const MANAGER_BASE_URL = '/dealers/managers/'
const ASSISTANT_BASE_URL = '/dealers/assistant-managers/'

/**
 *
 * Dashboard
 *
 */
const Dashboard = observer(() => {
  // Context
  const navigate = useNavigate()
  const location = useLocation()
  const {
    filters,
    resetFilters,
    selectedFilters,
    sortedColumn,
    setFilters,
    setSelectedFilters,
    setSortedColumn,
  } = useContext(DashboardStoreContext)
  const { isDealerUser, hasAcceptedTermsAndConditions, getUpdatedUser, user, setCurrentUser } =
    useContext(UserStoreContext)
  const { clearStore } = useContext(RootStoreContext)

  // State
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [dealers, setDealers] = useState([])
  const [kpis, setKpis] = useState(null)
  const [showTermsAndConditions, setShowTermsAndConditions] = useState()

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState()
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Update hovered state of dealer name
  const { hoveredItemId, handleMouseEnter, handleMouseLeave } = useHoverItem()

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  useEffect(() => {
    if (!location.pathname.includes('dashboard')) navigate('/dashboard', { replace: true })
  }, [])

  useEffect(() => {
    if (hasAcceptedTermsAndConditions === false && isDealerUser) {
      setShowTermsAndConditions(true)
    }
  }, [hasAcceptedTermsAndConditions])

  /**
   * Gets the updated list of dealers; updates pagination.
   * @param {string} url
   */
  const getUpdatedDealers = async (url) => {
    const response = await getDealers(url, () => {}, handleErrors)

    if (response) {
      setDealers(response.results)
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
    }
  }

  /**
   * Gets the updated dealer KPIs.
   * @param {string} url
   */
  const getUpdatedDealerKpis = async (url) => {
    const response = await getDealerKpis(url, () => {}, handleErrors)

    if (response) {
      // Transform histogram into the format we need
      const histogram = _.map(response.roAgeHistogram, (value, key) => ({
        days: key,
        age: value,
      }))

      setKpis({
        ...response,
        roAgeHistogram: histogram,
      })
    }
  }

  /**
   * Configures the filter query, without the specified `without` value.
   * @param {string} without
   */
  const configureFilterQuery = (without = null) => {
    const filterQuery = {
      group: selectedFilters.dealerGroup,
      manufacturer: selectedFilters.manufacturer,
      admin: selectedFilters.admin,
      assistant_manager: selectedFilters.assistantManager,
      manager: selectedFilters.manager,
    }

    if (without) delete filterQuery[without]

    return _.map(filterQuery, (value, key) => {
      if (value.length === 0) return null
      if (key === 'group') return `${key}=${_.map(value, 'id').join('&group=')}`
      return `${key}=${_.map(value, 'id').join(`&${key}=`)}`
    })
      .filter((x) => x !== null)
      .join('&')
  }

  useEffect(() => {
    const getUpdatedData = async () => {
      setLoading(true)
      setLoadingFilters(true)

      const dealerFilters = configureFilterQuery()
      const configureFilterOption = (values, isObject = false) =>
        _.orderBy(
          _.map(values, (o) => (isObject ? { id: o.id, label: o.name } : { id: o, label: o })),
          'label',
        )

      // Load all filter base options
      Promise.all([
        getFilterListOptions(`${DEALER_GROUP_BASE_URL}?${configureFilterQuery('group')}`),
        getFilterListOptions(`${MANUFACTURER_BASE_URL}?${configureFilterQuery('manufacturer')}`),
        getFilterListOptions(`${ADMIN_BASE_URL}?${configureFilterQuery('admin')}`),
        getFilterListOptions(`${ASSISTANT_BASE_URL}?${configureFilterQuery('assistant_manager')}`),
        getFilterListOptions(`${MANAGER_BASE_URL}?${configureFilterQuery('manager')}`),
      ]).then((values) => {
        setFilters({
          dealerGroup: configureFilterOption(values[0], true),
          manufacturer: configureFilterOption(values[1], true),
          admin: configureFilterOption(values[2]),
          assistantManager: configureFilterOption(values[3]),
          manager: configureFilterOption(values[4]),
        })
        setLoadingFilters(false)
      })

      // Load the updated data
      Promise.all([
        getUpdatedDealers(
          `${DEALER_BASE_URL}&limit=${perPage}&page=1&order_by=${sortedColumn}${
            dealerFilters ? `&${dealerFilters}` : ''
          }`,
        ),
        getUpdatedDealerKpis(`${KPI_BASE_URL}${dealerFilters ? `?${dealerFilters}` : ''}`),
      ]).then(() => {
        setLoading(false)
      })
    }

    getUpdatedData()
  }, [selectedFilters, perPage, sortedColumn])

  return (
    <div className="bg-background size-full flex flex-col overflow-y-auto px-4 pb-12 pt-6 sm:px-6 lg:px-8">
      <StateContainer>
        <div className="size-full flex flex-col">
          <span className="text-midnight mb-5 text-2xl font-semibold leading-8 tracking-[-1px]">
            Highlights
          </span>

          <Highlights kpis={kpis} />

          <div className="flex flex-col items-center gap-5 py-3 md:flex-row">
            <span className="text-charcoal-900 my-5 text-2xl font-semibold leading-8 tracking-[-1px]">
              Dealers
            </span>

            <div className="flex w-full flex-col flex-wrap items-center justify-end gap-2 md:flex-row">
              {_.values(selectedFilters).some((o) => o.length > 0) && (
                <button
                  className="mr-3 cursor-pointer rounded-md text-sm font-semibold leading-5 tracking-[0.25px] text-blue-800 hover:text-blue-900"
                  onClick={() => resetFilters()}
                  type="button"
                >
                  Reset Filters
                </button>
              )}
              {!isDealerUser && (
                <MultiSelectDropdown
                  className="w-full md:max-w-[250px]"
                  containerClassName="w-full md:w-auto"
                  disabled={loadingFilters}
                  id="dealer_group-filter"
                  onChange={(o) => setSelectedFilters({ ...selectedFilters, dealerGroup: o })}
                  options={filters.dealerGroup}
                  placeholder="Select a Dealer Group"
                  search
                  value={selectedFilters.dealerGroup}
                />
              )}
              <MultiSelectDropdown
                className="w-full md:max-w-[250px]"
                containerClassName="w-full md:w-auto"
                disabled={loadingFilters}
                id="manufacturer-filter"
                onChange={(o) => setSelectedFilters({ ...selectedFilters, manufacturer: o })}
                options={filters.manufacturer}
                placeholder="Select a Manufacturer"
                search
                value={selectedFilters.manufacturer}
              />

              <MultiSelectDropdown
                className="w-full md:max-w-[250px]"
                containerClassName="w-full md:w-auto"
                disabled={loadingFilters}
                id="admin-filter"
                onChange={(o) => setSelectedFilters({ ...selectedFilters, admin: o })}
                options={filters.admin}
                placeholder="Select an Admin"
                search
                value={selectedFilters.admin}
              />

              <MultiSelectDropdown
                className="w-full md:max-w-[250px]"
                containerClassName="w-full md:w-auto"
                disabled={loadingFilters}
                id="assistant_manager-filter"
                onChange={(o) => setSelectedFilters({ ...selectedFilters, assistantManager: o })}
                options={filters.assistantManager}
                placeholder="Select an Assistant Manager"
                search
                value={selectedFilters.assistantManager}
              />

              <MultiSelectDropdown
                className="w-full md:max-w-[250px]"
                containerClassName="w-full md:w-auto"
                disabled={loadingFilters}
                id="manager-filter"
                onChange={(o) => setSelectedFilters({ ...selectedFilters, manager: o })}
                options={filters.manager}
                placeholder="Select a Manager"
                search
                value={selectedFilters.manager}
              />
            </div>
          </div>

          <div className="grid">
            <DataTable
              onRowClicked={(row) => navigate(`/dealer/${row.id}/detail`)}
              highlightOnHover
              columns={[
                {
                  cell: (row) => (
                    <DealerNameCell
                      row={row}
                      hoveredRowId={hoveredItemId}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  ),
                  id: 'dealerName',
                  minWidth: '250px',
                  name: 'Dealer Name',
                  selector: (row) => row.name,
                  sortable: true,
                  sortBy: 'name',
                },
                {
                  grow: 0.25,
                  id: 'numberOfOpenRos',
                  name: 'ROs',
                  right: true,
                  selector: (row) => row.roMetrics?.numberOfOpenRos,
                  sortable: true,
                  sortBy: 'number_of_open_ros',
                },
                {
                  grow: 0.5,
                  id: 'totalUnresolvedClaimAmount',
                  format: (row) =>
                    Number(row.roMetrics?.totalUnresolvedClaimAmount).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }),
                  name: 'Total',
                  right: true,
                  selector: (row) => row.roMetrics?.totalUnresolvedClaimAmount,
                  sortable: true,
                  sortBy: 'total_unresolved_claim_amount',
                },
                {
                  id: 'dealerGroup',
                  minWidth: '200px',
                  name: 'Dealer Group',
                  selector: (row) => row.group?.name || 'N/A',
                  sortable: true,
                  sortBy: 'group__name',
                  omit: isDealerUser,
                },
                {
                  id: 'mfg',
                  grow: 0.75,
                  name: 'MFG',
                  selector: (row) => row.manufacturer?.name || 'N/A',
                  sortable: true,
                  sortBy: 'manufacturer__name',
                },
                {
                  cell: (row) => (
                    <div className="flex flex-row gap-1">
                      <img src={UserIcon} className="mr-1" alt="User" /> {row.admin || 'N/A'}
                    </div>
                  ),
                  id: 'admin',
                  mindWidth: '200px',
                  name: 'Admin',
                  selector: (row) => row.admin,
                  sortable: true,
                  sortBy: 'admin',
                },
                {
                  cell: (row) => (
                    <div className="flex flex-row gap-1">
                      <img src={UserIcon} className="mr-1" alt="User" />
                      {row.assistantManager || 'N/A'}
                    </div>
                  ),
                  id: 'assistantManager',
                  mindWidth: '200px',
                  name: 'Assistant Manager',
                  selector: (row) => row.assistantManager,
                  sortable: true,
                  sortBy: 'assistant_manager',
                },
                {
                  cell: (row) => (
                    <div className="flex flex-row gap-1">
                      <img src={UserIcon} className="mr-1" alt="User" /> {row.manager || 'N/A'}
                    </div>
                  ),
                  id: 'manager',
                  mindWidth: '200px',
                  name: 'Manager',
                  selector: (row) => row.manager,
                  sortable: true,
                  sortBy: 'manager',
                },
              ]}
              data={dealers}
              onChangePage={(page) => {
                const dealerFilters = configureFilterQuery()
                handlePagination(
                  page,
                  currentPage,
                  perPage,
                  totalRows,
                  pages,
                  setCurrentPage,
                  getUpdatedDealers,
                  `${DEALER_BASE_URL}&limit=`,
                  `order_by=${sortedColumn}${dealerFilters ? `&${dealerFilters}` : ''}`,
                )
              }}
              onChangeRowsPerPage={(currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              onSort={(column, direction) => {
                const d = direction === 'asc' ? '' : '-'
                setSortedColumn(`${d}${column.sortBy}`)
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
              paginationTotalRows={totalRows}
              progressPending={loading}
              sortServer
            />
          </div>

          {showTermsAndConditions && (
            <Modal
              open
              title="Terms and Conditions"
              onClose={() => {}}
              setOpen={() => {}}
              description="You must agree to the terms and conditions to access the site."
              content={
                <div className="relative my-4 h-[300px] overflow-y-scroll px-2">
                  <p className="text-charcoal-900 text-md">
                    {user.dealerUserProfile?.role === 'Service'
                      ? 'By registering and using the WARP Platform, You acknowledge and agree that the queries, questions, design, layout, and organization that are made and presented to You by the WARP Platform as well as the compilation of information that is input by You into the WARP Platform (collectively, “Compilations”) are proprietary, confidential, and trade secret of Warranty Processing Company, Inc. You agree not to distribute or otherwise disclose the Compilations to another party or another person, and You agree that any disclosure of the Compilations to another party or another person is prohibited and shall cause irreparable harm to Warranty Processing Company, Inc.'
                      : 'You hereby represent that you have authority on behalf of Dealer and, by Dealer registering and using the WARP Platform, You acknowledge and agree that the queries, questions, design, layout, and organization that are made and presented to Dealer and its users by the WARP Platform as well as the compilation of information that is input by Dealer into the WARP Platform (collectively, “Compilations”) are proprietary, confidential, and trade secret of Warranty Processing Company, Inc. You and Dealer agree not to distribute or otherwise disclose the Compilations to another party or another person, and You and Dealer agree that any disclosure of the Compilations to another party or another person is prohibited and shall cause irreparable harm to Warranty Processing Company, Inc.'}
                  </p>
                  <div className="from-white-dark pointer-events-none sticky bottom-0 left-0 h-[50px] w-full bg-gradient-to-t to-transparent"></div>
                </div>
              }
              actions={[
                {
                  type: 'cancel',
                  label: 'Logout',
                  onClick: () => logout(user.refreshToken, clearStore),
                },
                {
                  type: 'submit',
                  label: 'I Agree',
                  onClick: async () => {
                    await updateUser(
                      { id: user.id, acceptedTermsAndConditionsAt: new Date().toISOString() },
                      handleErrors,
                      () => {},
                      async () => {
                        handleSuccess('Terms and Conditions accepted.')
                        setShowTermsAndConditions(false)

                        await getUpdatedUser(user.id)
                      },
                      setCurrentUser,
                    )
                  },
                },
              ]}
            />
          )}
        </div>
      </StateContainer>
    </div>
  )
})

export default Dashboard
