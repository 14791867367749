/** @todo Fix import cycle */
/* eslint-disable import/no-cycle */

import * as Sentry from '@sentry/react'
import { action } from 'mobx'
import { createContext } from 'react'

// Stores
import { refreshAccessToken as refreshAccessTokenService } from '../services/user.service'
import { dashboard } from './DashboardStore'
import { display } from './DisplayStore'
import { notifications } from './NotificationStore'
import { task } from './TaskStore'
import { user } from './UserStore'

export class RootStore {
  user = user

  dashboard = dashboard

  display = display

  notifications = notifications

  task = task

  clearStore = action(() => {
    this.dashboard.reset()
    this.display.reset()
    this.notifications.reset()
    this.task.reset()
    this.user.reset()

    Sentry.setUser(null)
  })

  triggerRefreshToken = action(async (failedRequest) => {
    try {
      const { access, refresh } = await refreshAccessTokenService(this.user.refreshToken)
      this.user.accessToken = access
      this.user.refreshToken = refresh
      // Reassign to `failedRequest` as this object is used by the token
      // interceptor to retry the request
      if (failedRequest)
        // eslint-disable-next-line no-param-reassign
        failedRequest.response.config.headers.Authorization = `Bearer ${access}`
    } catch (err) {
      this.clearStore()
      throw err
    }
  })
}

export const store = new RootStore()
export const RootStoreContext = createContext(store)
