import PropTypes from 'prop-types'
import React from 'react'

// Utils
import { CYCLE_STATES } from '../../utils/constants'

const BillingCycleBadge = ({ status }) => {
  if (!status) return null

  const { text, color } = CYCLE_STATES[status] || {
    text: 'Unknown Status',
    color: 'bg-white-dark text-gray-600',
  }

  return (
    <span className={`inline-block rounded-full px-3 py-1 text-xs font-medium uppercase ${color}`}>
      {text}
    </span>
  )
}

BillingCycleBadge.propTypes = {
  status: PropTypes.string,
}

export default BillingCycleBadge
