import PropTypes from 'prop-types'
import React from 'react'

// Utils & Service
import { formatCurrency } from '../../utils/formatters'

const ExpandedCreditMemoRow = ({ data }) => {
  if (!data) return null
  return (
    <div className="bg-white-dark flex flex-row items-start gap-6 px-3 py-2">
      <div className="flex w-[190px] shrink-0 flex-col px-2">
        <span className="text-charcoal-400 text-xs font-bold uppercase">Attachments</span>
        {data.files?.length === 0 ? (
          <span className="text-sm">No Files Attached.</span>
        ) : (
          <ul className="text-charcoal-900 text-sm">
            {data.files?.map((file) => (
              <li key={file.id}>
                <a
                  href={file.file}
                  target="_blank"
                  className="text-sm text-blue-800 hover:underline"
                >
                  {file.displayName}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex w-[250px] shrink-0 flex-col px-2">
        <span className="text-charcoal-400 text-xs font-bold uppercase">Add Back Details</span>
        {data.addBacks?.length === 0 ? (
          <span className="text-sm">No Add Backs.</span>
        ) : (
          <ul className="text-charcoal-900 text-sm">
            {data.addBacks?.map((addBack) => (
              <li key={addBack.id} className="truncate">
                {formatCurrency(addBack.amount)} - {addBack.description}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex w-[250px] shrink-0 flex-col px-2">
        <span className="text-charcoal-400 text-xs font-bold uppercase">Unbillable Details</span>
        {data.unbillables?.length === 0 ? (
          <span className="text-sm">No Unbillables.</span>
        ) : (
          <ul className="text-charcoal-900 text-sm">
            {data.unbillables?.map((unbillable) => (
              <li key={unbillable.id} className="truncate">
                {formatCurrency(unbillable.amount)} - {unbillable.description}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

ExpandedCreditMemoRow.propTypes = {
  data: PropTypes.object,
}

export default ExpandedCreditMemoRow
