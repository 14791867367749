import dayjs from 'dayjs'

export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString) {
    const cleaned = ('', phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
  }
  return ''
}

export const formatMonthName = (monthNumber) => {
  if (!monthNumber) return ''
  // Create a dayjs object using the first day of the given month
  return dayjs()
    .month(monthNumber - 1)
    .format('MMMM')
}

export const formatCurrency = (amount) =>
  Number(amount || 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
