export const ACTION_ICONS = {
  'Provide coverage explanation': 'paper-check',
  'Provide labor operation(s)': 'paper-check',
  'Provide approval code (PA)': 'code',
  'Upload/send supporting docs': 'upload',
  'Update customer complaint in DMS': 'person',
  'Provide valid customer complaint': 'person',
  'Update diagnostic code(s)/comments in DMS': 'memo-check',
  'Provide diagnostic code(s)/comments': 'memo-check',
  'Update factory defect comments in DMS': 'memo-check',
  'Provide factory defect comments': 'memo-check',
  'Update repair comments in DMS': 'memo-check',
  'Provide repair comments': 'memo-check',
  'Verify and provide correct mileage': 'mileage',
  'Add advisor/tech to MFG system': 'add',
  'Potential writeoff': 'paper',
  'Other - see problem notes': 'more',
  'Correct parts billed on RO': 'dollar',
  'Need management approval': 'id-badge',
}

export const CYCLE_STATES = {
  'Awaiting Approval': {
    text: 'Awaiting Approval',
    color: 'bg-blue-200 text-blue-950',
  },
  Closed: {
    text: 'Closed',
    color: 'bg-red text-white',
  },
  'Not Started': {
    text: 'Not Started',
    color: 'bg-white-dark text-blue-950',
  },
  'In Progress': {
    text: 'In Progress',
    color: 'bg-white-dark text-blue-950',
  },
  'Submitted to Billing': {
    text: 'Submitted',
    color: 'bg-[#41A200] text-white',
  },
}
