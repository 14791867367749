import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// Components
import { BillingCycleBadge } from '../../components/BillingCycleBadge'
import { DataTable } from '../../components/DataTable'
import { MultiSelectDropdown } from '../../components/MultiSelectDropdown'
import { NameWithIcon } from '../../components/NameWithIcon'

// Utils & Services
import { getDealersBillingMetrics } from '../../services/billing.service'
import { getFilterListOptions } from '../../services/dealers.service'
import { formatCurrency } from '../../utils/formatters'
import { handlePagination, toast } from '../../utils/helpers'

const DEALER_BASE_URL = '/dealers/?expand=group,billing_metrics,manufacturer'
const DEALER_GROUP_BASE_URL = '/dealers/dealer-groups/'
const MANUFACTURER_BASE_URL = '/dealers/manufacturers/'
const TEAM_BASE_URL = '/dealers/team/'

const DEFAULT_FILTERS = {
  dealerGroup: [],
  manufacturer: [],
  team: [],
  submissionStatus: [],
  currentMonthSubmissionStatus: false,
  previousMonthSubmissionStatus: false,
}

const renderBillingCycleBadge = (status) => <BillingCycleBadge status={status} />
const renderTeam = (row) => (
  <div className="mb-2">
    <NameWithIcon name={row.admin} label="A" ariaText="Admin" />
    <NameWithIcon name={row.assistantManager} label="AM" ariaText="Assistant Manager" />
    <NameWithIcon name={row.manager} label="M" ariaText="Manager" />
  </div>
)
const renderDealerDetails = (row) => (
  <div className="my-2">
    <Link
      to={`/billing/${row.id}/detail`}
      className="text-sm font-medium leading-5 tracking-[0.25px] text-blue-800"
    >
      {row.name}
    </Link>
    <div className="mt-2 text-sm font-medium leading-5 tracking-[0.25px]">
      {row.group?.name || 'N/A'}
    </div>
    <div className="mt-2 text-sm font-medium leading-5 tracking-[0.25px]">
      {row.manufacturer?.name || 'N/A'}
    </div>
  </div>
)
const renderCurrency = (value) => <span className="text-sm">{formatCurrency(value)}</span>

const Dealers = ({ currentMonth = '', previousMonth = '' }) => {
  const navigate = useNavigate()

  // State
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [dealers, setDealers] = useState([])
  const [sortedColumn, setSortedColumn] = useState('name')
  const [filters, setFilters] = useState({
    dealerGroup: [],
    manufacturer: [],
    team: [],
    submissionStatus: [],
  })
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTERS)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState()
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  const handleErrors = (m) => toast(m, 'error')

  const columns = useMemo(
    () => [
      {
        cell: (row) => renderDealerDetails(row),
        id: 'dealerName',
        width: '250px',
        name: 'Dealer Name',
        selector: (row) => row.name,
        sortable: true,
        sortBy: 'name',
      },
      {
        id: 'currentMonthTotal',
        name: <span className="whitespace-normal text-left">{currentMonth} Running Total</span>,
        format: (row) => renderCurrency(row.billingMetrics?.currentMonthTotal),
        selector: (row) => row.billingMetrics?.currentMonthTotal,
        sortable: true,
        sortBy: 'current_month_total',
      },
      {
        grow: 0.5,
        id: 'currentMonthSubmissionStatus',
        name: (
          <span className="whitespace-normal text-left">{currentMonth} Submission Status</span>
        ),
        format: (row) => renderBillingCycleBadge(row.billingMetrics?.currentMonthState),
        selector: (row) => row.billingMetrics?.currentMonthState,
        sortable: true,
        sortBy: 'current_month_state',
        width: '250px',
      },
      {
        id: 'previousMonthTotal',
        name: <span className="whitespace-normal text-left">{previousMonth} Total</span>,
        format: (row) => renderCurrency(row.billingMetrics?.previousMonthTotal),
        selector: (row) => row.billingMetrics?.previousMonthTotal,
        sortable: true,
        sortBy: 'previous_month_total',
      },
      {
        grow: 0.5,
        id: 'previousMonthSubmissionStatus',
        name: (
          <span className="whitespace-normal text-left">{previousMonth} Submission Status</span>
        ),
        format: (row) => renderBillingCycleBadge(row.billingMetrics?.previousMonthState),
        selector: (row) => row.billingMetrics?.previousMonthState,
        sortable: true,
        sortBy: 'previous_month_state',
        width: '250px',
      },
      {
        cell: (row) => renderTeam(row),
        id: 'admin',
        width: '250px',
        name: 'WPC Team',
        selector: (row) => row.admin,
        sortable: false,
      },
    ],
    [currentMonth, previousMonth],
  )

  /**
   * Gets the updated list of dealers; updates pagination.
   * @param {string} url
   */
  const getUpdatedDealersMetrics = async (url) => {
    const response = await getDealersBillingMetrics(url, setLoading, handleErrors)

    if (response) {
      setDealers(response.results)
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
    }
  }

  /**
   * Configures the filter query, without the specified `without` value.
   * @param {string} without
   */
  const configureFilterQuery = (without = null) => {
    const filterQuery = {
      group: selectedFilters.dealerGroup,
      manufacturer: selectedFilters.manufacturer,
      team: selectedFilters.team,
    }

    if (without) delete filterQuery[without]

    return _.map(filterQuery, (value, key) => {
      if (value.length === 0) return null
      if (key === 'group') return `${key}=${_.map(value, 'id').join('&group=')}`
      return `${key}=${_.map(value, 'id').join(`&${key}=`)}`
    })
      .filter((x) => x !== null)
      .join('&')
  }

  useEffect(() => {
    const getUpdatedData = async () => {
      setLoading(true)
      setLoadingFilters(true)

      const dealerFilters = configureFilterQuery()
      const configureFilterOption = (values, isObject = false) =>
        _.orderBy(
          _.map(values, (o) => (isObject ? { id: o.id, label: o.name } : { id: o, label: o })),
          'label',
        )

      Promise.all([
        getFilterListOptions(`${DEALER_GROUP_BASE_URL}?${configureFilterQuery('group')}`),
        getFilterListOptions(`${MANUFACTURER_BASE_URL}?${configureFilterQuery('manufacturer')}`),
        getFilterListOptions(`${TEAM_BASE_URL}?${configureFilterQuery('team')}`),
      ]).then((values) => {
        setFilters({
          dealerGroup: configureFilterOption(values[0], true),
          manufacturer: configureFilterOption(values[1], true),
          team: configureFilterOption(values[2]),
          submissionStatus: [
            { label: `${previousMonth} Not Submitted`, id: 'previousMonthSubmissionStatus' },
            { label: `${currentMonth} Not Submitted`, id: 'currentMonthSubmissionStatus' },
          ],
        })
        setLoadingFilters(false)
      })

      await getUpdatedDealersMetrics(
        `${DEALER_BASE_URL}&limit=${perPage}&page=1&order_by=${sortedColumn}&billing_cycle__previous_outstanding=${
          selectedFilters.previousMonthSubmissionStatus || ''
        }&billing_cycle__current_outstanding=${
          selectedFilters.currentMonthSubmissionStatus || ''
        }${dealerFilters ? `&${dealerFilters}` : ''}`,
      )
    }

    getUpdatedData()
  }, [selectedFilters, perPage, sortedColumn, currentMonth, previousMonth])

  return (
    <div className="mt-6">
      <div className="flex flex-col items-center gap-5 py-3 md:flex-row">
        <h2 className="text-charcoal-900 text-2xl font-semibold">Dealers</h2>
        <div className="flex w-full flex-col flex-wrap items-center justify-end gap-2 md:flex-row">
          {_.values(selectedFilters).some((o) => o.length > 0) && (
            <button
              className="mr-3 cursor-pointer rounded-md text-sm font-semibold leading-5 tracking-[0.25px] text-blue-800 hover:text-blue-900"
              onClick={() => setSelectedFilters(DEFAULT_FILTERS)}
              type="button"
            >
              Reset Filters
            </button>
          )}

          <MultiSelectDropdown
            className="w-full md:max-w-[250px]"
            containerClassName="w-full md:w-auto"
            disabled={loadingFilters}
            id="submission-status-filter"
            onChange={(selectedOptions) => {
              // Get the IDs of the currently selected options
              const selectedIds = selectedOptions.map((option) => option.id)
              const submissionKeys = [
                'currentMonthSubmissionStatus',
                'previousMonthSubmissionStatus',
              ]
              const updatedFilters = submissionKeys.reduce((acc, key) => {
                acc[key] = selectedIds.includes(key) // Set true if the key is in selectedIds, else false
                return acc
              }, {})
              setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                ...updatedFilters,
                submissionStatus: selectedOptions,
              }))
            }}
            options={filters.submissionStatus}
            placeholder="Select a Submission Status"
            search
            value={selectedFilters.submissionStatus}
          />
          <MultiSelectDropdown
            className="w-full md:max-w-[250px]"
            containerClassName="w-full md:w-auto"
            disabled={loadingFilters}
            id="dealer-group-filter"
            onChange={(o) => setSelectedFilters({ ...selectedFilters, dealerGroup: o })}
            options={filters.dealerGroup}
            placeholder="Select a Dealer Group"
            search
            value={selectedFilters.dealerGroup}
          />

          <MultiSelectDropdown
            className="w-full md:max-w-[250px]"
            containerClassName="w-full md:w-auto"
            disabled={loadingFilters}
            id="manufacturer-filter"
            onChange={(o) => setSelectedFilters({ ...selectedFilters, manufacturer: o })}
            options={filters.manufacturer}
            placeholder="Select a Manufacturer"
            search
            value={selectedFilters.manufacturer}
          />

          <MultiSelectDropdown
            className="w-full md:max-w-[250px]"
            containerClassName="w-full md:w-auto"
            disabled={loadingFilters}
            id="team-filter"
            onChange={(o) => setSelectedFilters({ ...selectedFilters, team: o })}
            options={filters.team}
            placeholder="Select a Team Member"
            search
            value={selectedFilters.team}
          />
        </div>
      </div>
      <DataTable
        onRowClicked={(row) => navigate(`/billing/${row.id}/detail`)}
        highlightOnHover
        columns={columns}
        data={dealers}
        onChangePage={(page) => {
          const dealerFilters = configureFilterQuery()
          handlePagination(
            page,
            currentPage,
            perPage,
            totalRows,
            pages,
            setCurrentPage,
            getUpdatedDealersMetrics,
            `${DEALER_BASE_URL}&limit=`,
            `order_by=${sortedColumn}${dealerFilters ? `&${dealerFilters}` : ''}`,
          )
        }}
        onChangeRowsPerPage={(currentRowsPerPage) => setPerPage(currentRowsPerPage)}
        onSort={(column, direction) => {
          const d = direction === 'asc' ? '' : '-'
          setSortedColumn(`${d}${column.sortBy}`)
        }}
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
        paginationTotalRows={totalRows}
        progressPending={loading}
        sortServer
      />
    </div>
  )
}

Dealers.propTypes = {
  currentMonth: PropTypes.string,
  previousMonth: PropTypes.string,
}

export default Dealers
