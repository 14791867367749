import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { Dialog } from '../../components/Dialog'
import { Select } from '../../components/Select'
import ExpandedCreditMemoRow from './ExpandedCreditMemoRow'

// Icons
import UserIcon from '../../assets/images/user_icon.svg'
import { AddIcon } from '../../components/AddIcon'
import { MinusIcon } from '../../components/MinusIcon'
import { PencilIcon } from '../../components/PencilIcon'
import { TrashIcon } from '../../components/TrashIcon'

// Utils & Service
import {
  deleteCreditMemo,
  getDealerBillingCycles,
  getDealerCreditMemos,
} from '../../services/billing.service'
import { formatCurrency, formatMonthName } from '../../utils/formatters'
import { handlePagination, toast } from '../../utils/helpers'

const CreditMemos = ({ dealerId = '' }) => {
  // State
  const [memos, setMemos] = useState([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [billingCycles, setBillingCycles] = useState([])
  const [selectedBillingCycle, setSelectedBillingCycle] = useState({})
  const [selectedCreditMemo, setSelectedCreditMemo] = useState({})
  const [sortedColumn, setSortedColumn] = useState('date')
  const [loadingBillingCycle, setLoadingBillingCycle] = useState(true)
  const [loadingCreditMemos, setLoadingCreditMemos] = useState(true)
  const [isDeletingCreditMemo, setIsDeletingCreditMemo] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState()
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  const MEMOS_BASE_URL = `/dealers/${dealerId}/billing-cycles/${selectedBillingCycle?.id}/credit-memos/?expand=created_by,add_backs,unbillables,files`

  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of credit; updates pagination.
   * @param {string} url
   */
  const getUpdatedMemos = async (url) => {
    const response = await getDealerCreditMemos(url, setLoadingCreditMemos, handleErrors)

    if (response) {
      setMemos(response.results)
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
    }
  }

  const fetchBillingCycles = async () => {
    const data = await getDealerBillingCycles(dealerId, setLoadingBillingCycle, handleErrors)

    if (data?.count > 0) {
      setBillingCycles(
        _.map(data.results, (cycle) => ({
          id: cycle.id,
          label: `${formatMonthName(cycle?.period?.month)} ${cycle?.period?.year}`,
          year: cycle?.period?.year,
          month: cycle?.period?.month,
          state: cycle.state,
        })),
      )
    } else {
      setBillingCycles([]) // Clear billing cycles if none are returned
      setMemos([]) // Clear memos since no billing cycles exist
      setSelectedBillingCycle({}) // Reset selected billing cycle
      setLoadingBillingCycle(false)
      setLoadingCreditMemos(false)
    }
  }

  useEffect(() => {
    setBillingCycles([])
    setSelectedBillingCycle({})
    setMemos([])

    if (dealerId) {
      fetchBillingCycles()
    }
  }, [dealerId])

  useEffect(() => {
    if (billingCycles.length > 0) {
      // Find the oldest non-submitted billing cycle
      const defaultCycle = _.findLast(
        billingCycles,
        (cycle) => cycle.state !== 'Submitted to Billing' && cycle.state !== 'Closed',
      )

      if (defaultCycle) {
        setSelectedBillingCycle(defaultCycle)
      } else {
        setSelectedBillingCycle(billingCycles[0]) // Fallback to the first in the list
      }
    } else {
      setSelectedBillingCycle({}) // Reset selectedBillingCycle if no billing cycles
      setMemos([]) // Clear memos when there are no billing cycles
    }
  }, [billingCycles])

  useEffect(() => {
    if (selectedBillingCycle?.id) {
      getUpdatedMemos(
        `${MEMOS_BASE_URL}&limit=${perPage}&page=${currentPage}&order_by=${sortedColumn}`,
      )
    } else {
      setMemos([]) // Clear memos if no valid selectedBillingCycle
    }
  }, [selectedBillingCycle, currentPage, perPage, sortedColumn])

  const renderEditMemoButton = (row) => (
    <div>
      <button
        aria-label={`Edit Credit Memo ${row.memoId}`}
        onClick={() => {}}
        className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
        type="button"
      >
        <PencilIcon className="h-4 stroke-white" />
      </button>
      <button
        aria-label={`Delete Credit Memo ${row.memoId}`}
        onClick={() => {
          setSelectedCreditMemo(row)
          setShowDeleteDialog(true)
        }}
        className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
        type="button"
      >
        <TrashIcon className="h-4 stroke-white" />
      </button>
    </div>
  )

  const renderAddedBy = (row) => (
    <div className="flex flex-row gap-1">
      <img src={UserIcon} className="mr-1" alt="" />
      {`${row.createdBy?.firstName || ''} ${row.createdBy?.lastName || ''}`}
    </div>
  )

  const renderActionButtons = (status, selectedMonth) => {
    const formattedMonth = dayjs()
      .month(selectedMonth - 1)
      .format('MMMM')

    switch (status) {
      case 'In Progress':
        return (
          <div className="m-auto pt-8">
            <Button
              className="px-4 py-2"
              dataTestId="submitCreditMemos"
              label={`Submit ${formattedMonth} Memos for Approval`}
            />
          </div>
        )

      case 'Awaiting Approval':
        return (
          <div className="m-auto flex gap-4 pt-8">
            <Button
              className="px-4 py-2"
              dataTestId="rejectCreditMemos"
              label="Reject"
              outlined
              background="bg-background"
            />
            <Button className="px-3 py-2" dataTestId="approveCreditMemos" label="Approve" />
          </div>
        )

      case 'Not Started':
      case 'Submitted to Billing':
      case 'Closed':
      default:
        return null
    }
  }

  const columns = [
    {
      name: 'Memo Date',
      selector: (row) => dayjs(row.date).format('MM/DD/YYYY'),
      sortable: true,
      sortBy: 'date',
      minWidth: '150px',
    },
    {
      name: 'Credit Memo ID',
      selector: (row) => row.memoId,
      sortable: true,
      sortBy: 'memo_id',
      minWidth: '200px',
    },
    {
      name: 'Billing Total',
      selector: (row) => formatCurrency(row.totalAmount),
      sortable: true,
      sortBy: 'total_amount',
      minWidth: '150px',
    },
    {
      name: 'Memo Amount',
      selector: (row) => formatCurrency(row.memoAmount),
      sortable: true,
      sortBy: 'memo_amount',
      minWidth: '150px',
    },
    {
      name: 'Add Backs Total',
      selector: (row) => formatCurrency(row.totalAddBacks),
      sortable: true,
      sortBy: 'total_add_backs',
      minWidth: '150px',
    },
    {
      name: 'Unbillables',
      selector: (row) => `(${formatCurrency(row.totalUnbillables)})`,
      sortable: true,
      sortBy: 'total_unbillables',
      minWidth: '150px',
    },
    {
      name: 'Added By',
      cell: (row) => (row.createdBy && renderAddedBy(row)) || 'N/A',
      sortable: true,
      sortBy: 'created_by__first_name',
      minWidth: '250px',
    },
    {
      name: 'Actions',
      cell: renderEditMemoButton,
      minWidth: '100px',
    },
  ]

  return (
    <div className="pt-8">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-wrap items-center gap-4">
          {selectedBillingCycle?.id && (
            <Select
              className="relative w-full cursor-default rounded-md     border border-none bg-transparent px-3 py-1 pl-0 text-left text-gray-900 shadow-none"
              inputTextClassName="text-lg"
              options={billingCycles}
              value={selectedBillingCycle}
              onChange={setSelectedBillingCycle}
              disabled={billingCycles.length === 0}
              showBadge
            />
          )}
        </div>
        <div>
          <Button
            icon={<AddIcon className="h-4 stroke-white" />}
            label="Add Credit Memo"
            size="sm"
            dataTestId="addCreditMemo"
            disabled={!selectedBillingCycle?.id}
          />
        </div>
      </div>
      <div className="mt-4">
        <DataTable
          columns={columns}
          data={memos}
          onSort={(column, direction) => {
            const d = direction === 'asc' ? '' : '-'
            setSortedColumn(`${d}${column.sortBy}`)
          }}
          progressPending={loadingCreditMemos || loadingBillingCycle}
          sortServer
          expandableRows
          expandableIcon={{
            collapsed: (
              <div className="flex flex-row gap-3 pl-3">
                <AddIcon className="h-4 stroke-blue-800" />
              </div>
            ),
            expanded: (
              <div className="flex flex-row gap-3 pl-4">
                <MinusIcon className="h-4 stroke-blue-800" />
              </div>
            ),
          }}
          expandableRowsComponent={ExpandedCreditMemoRow}
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={(currentRowsPerPage) => setPerPage(currentRowsPerPage)}
          onChangePage={(page) => {
            handlePagination(
              page,
              currentPage,
              perPage,
              totalRows,
              pages,
              setCurrentPage,
              getUpdatedMemos,
              `${MEMOS_BASE_URL}&limit=`,
              `order_by=${sortedColumn}`,
            )
          }}
        />
      </div>

      <div className="m-auto flex">
        {renderActionButtons(selectedBillingCycle.state, selectedBillingCycle.month)}
      </div>

      {showDeleteDialog && selectedCreditMemo && (
        <Dialog
          title="Delete Credit Memo"
          type="warning"
          message={`Are you sure you want to delete this credit memo (ID: ${selectedCreditMemo.memoId})?`}
          onConfirm={() =>
            deleteCreditMemo(
              dealerId,
              selectedBillingCycle.id,
              selectedCreditMemo.id,
              setIsDeletingCreditMemo,
              handleErrors,
              () => {
                setShowDeleteDialog(false)
                setSelectedCreditMemo({})

                getUpdatedMemos(
                  `${MEMOS_BASE_URL}&limit=${perPage}&page=${currentPage}&order_by=${sortedColumn}`,
                )
              },
            )
          }
          onCancel={() => setShowDeleteDialog(false)}
          loading={isDeletingCreditMemo}
        />
      )}
    </div>
  )
}
CreditMemos.propTypes = {
  dealerId: PropTypes.string,
}

export default CreditMemos
