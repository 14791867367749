import { createContext } from 'react'
import { action, observable } from 'mobx'

// Store
import { BaseStore } from './BaseStore'

const DEFAULT_FILTERS = {
  dealerGroup: [],
  manufacturer: [],
  admin: [],
  assistantManager: [],
  manager: [],
}

const INITIAL_STATE = {
  loading: false,
  lastUpdated: null,
  error: null,
  filters: DEFAULT_FILTERS,
  selectedFilters: DEFAULT_FILTERS,
  sortedColumn: 'name',
}

const TYPES = {
  data: observable,
  loading: observable,
  error: observable,
  lastUpdated: observable,
  filters: observable,
  selectedFilters: observable,
  sortedColumn: observable,
}

export class DashboardStore extends BaseStore {
  setFilters = action((filters) => {
    this.filters = filters
  })

  setSelectedFilters = action((filters) => {
    this.selectedFilters = filters
  })

  setSortedColumn = action((column) => {
    this.sortedColumn = column
  })

  resetFilters = action(() => {
    this.selectedFilters = DEFAULT_FILTERS
  })
}

export const dashboard = new DashboardStore(INITIAL_STATE, TYPES)
export const DashboardStoreContext = createContext(dashboard)
