import PropTypes from 'prop-types'
import React from 'react'
// Icon
import UserIcon from '../../assets/images/user_icon.svg'

const NameWithIcon = ({ name, fallback = 'N/A', label = '', ariaText = '' }) => (
  <div className="mt-2 grid grid-cols-[40px_1fr] items-center gap-2">
    {label ? (
      <div className="text-right">
        <span
          className="text-midnight h-full w-full rounded bg-gray-200 p-[2.5px] text-sm font-bold uppercase"
          aria-hidden={ariaText ? 'true' : 'false'}
        >
          {label}
        </span>
        <span className="sr-only">{ariaText}</span>
      </div>
    ) : (
      <img src={UserIcon} className="mr-1" alt={ariaText} />
    )}
    <span className="whitespace-normal break-words text-sm">{name || fallback}</span>
  </div>
)
export default NameWithIcon

NameWithIcon.propTypes = {
  name: PropTypes.string,
  fallback: PropTypes.string,
  label: PropTypes.string,
  ariaText: PropTypes.string,
}
