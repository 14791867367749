import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import React, { Fragment, useRef, useState } from 'react'

// Components
// eslint-disable-next-line import/no-cycle
import { Button } from '../Button'

// Utils
import { joinClassNames } from '../../utils/helpers'

const Dialog = ({ loading, message, onCancel, onConfirm, title, type }) => {
  // State
  const [open, setOpen] = useState(true)

  // Ref
  const cancelButtonRef = useRef(null)

  const configureColor = () => {
    switch (type) {
      case 'success':
        return 'green'
      case 'error':
        return 'red'
      case 'warning':
        return 'orange'
      case 'info':
        return 'blue'
      default:
        return 'blue'
    }
  }

  const renderIcon = () => {
    let icon = null
    const background = `bg-${configureColor()}-100`

    if (type === 'success') {
      icon = <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
    } else if (type === 'error') {
      icon = <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
    } else if (type === 'warning') {
      icon = <ExclamationTriangleIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
    } else if (type === 'info') {
      icon = <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
    }

    return (
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={joinClassNames(
          'mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
          background,
        )}
      >
        {icon}
      </div>
    )
  }

  return (
    <Transition show={open} as={Fragment}>
      <HeadlessDialog
        open={open}
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="bg-white-dark relative overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex flex-col items-center justify-center">
                  <div className="my-3 text-center sm:mt-0">
                    <div className="flex items-center justify-center">
                      {renderIcon()}
                      <DialogTitle
                        as="h2"
                        className="text-2xl font-bold leading-none text-gray-700"
                      >
                        {title}
                      </DialogTitle>
                    </div>
                    <div className="mt-8 sm:text-left">
                      <p className="text-md text-gray-700">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 gap-2 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button label="Confirm" loading={loading} size="sm" onClick={onConfirm} />
                  <Button
                    type="button"
                    label="Cancel"
                    size="sm"
                    background="bg-background"
                    loading={loading}
                    onClick={() => {
                      setOpen(false)
                      if (onCancel) onCancel()
                    }}
                    outlined
                    ref={cancelButtonRef}
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}

Dialog.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

Dialog.defaultProps = {
  onCancel: null,
  loading: false,
}

export default Dialog
